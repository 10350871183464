import React, { Component } from 'react';
import { isIPAddress } from 'ip-address-validator';

import WidgetRow from "./widgets/WidgetRow";
import DynamicContentHead from "./DynamicContentHead";
import { toast } from 'react-toastify';

class MyComponent extends Component {

    constructor(props) {
        super(props)
        this.nodes = JSON.parse(localStorage.getItem('nodes')) || []
        if (this.nodes.length === 0) {
            this.nodes = ["near_validator:3030"]
        }
        this.data = []
        this.state = {
            data: []
        }
        this.environments = ["testnet", "mainnet"]
        this.proxyURL = "https://proxy.cunum.com?url="
        this.validatorStatus = {}
        this.validatorStake = {}
        this.validatorShards = {}
        this.epochStartHeight = {}
        this.delegators = {}
        this.seatPrice = {}
        this.blockUpdateState = false
        this.lastTime = {}
        this.lastBlocksProcessedTotal = {}
        this.lastTransactionProcessedTotal = {}
    }

    componentDidMount() {
        if (this.nodes.length > 0) {
            toast.info("Loading previously configured nodes, please wait...")
        }
        this.initializeNodes()
        this.intervalWidgets = setInterval(this.updateWidgets.bind(this), 5 * 1000)
        this.intervalValidatorStatus = setInterval(this.updateValidatorStatus.bind(this), 5 * 60 * 1000)
        this.intervalDelegators = setInterval(this.updateDelegators.bind(this), 20 * 60 * 1000)
        this.intervalStatus = setInterval(this.updateStatus.bind(this), 60 * 1000)
    }

    updateStatus() {
        this.data.forEach((row, index) => {
            if (!row.accountId) {
                fetch(this.proxyURL + "http://" + row.node + "/status")
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            if (data.validator_account_id) {
                                this.data[index].accountId = data.validator_account_id
                            }
                        }
                    })
                    .catch((e) => { console.error(e) })
            }
        })
    }

    updateDelegators() {
        this.state.data.forEach((row, rowIndex) => {
            this.rpcFetch(row.environment, "query", {
                    "request_type": "call_function",
                    "finality": "final",
                    "account_id": row.accountId,
//                            "method_name": "get_accounts",
                    "method_name": "get_number_of_accounts",
                    "args_base64": btoa(JSON.stringify({
                        "from_index": 0,
                        "limit": 100000
                    }))
                })
                .then((data) => {
                    const delegatorCount = JSON.parse(String.fromCharCode(...data.result.result))
                    this.delegators[row.accountId] = delegatorCount
                })
                .catch((error) => console.error(error))
        })
    }

    rpcFetch(environment, method, params = [null]) {
        return fetch("https://rpc." + environment + ".pagoda.co", {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json'
           },
           body: JSON.stringify({
               "jsonrpc": "2.0",
               "id": "dontcare",
               "method": method,
               "params": params
           })
        })
        .then((response) => response.json())
    }

    updateConfigInfo() {
        this.epochLength = {}
        this.environments.forEach((environment) => {
            this.rpcFetch(environment, "EXPERIMENTAL_genesis_config")
                .then((data) => {
                    this.epochLength[environment] = data.result.epoch_length
                })
                .catch((e) => {
                    console.error(e);
                })
        })
    }

    componentWillUnmount() {
        clearInterval(this.intervalWidgets)
        clearInterval(this.intervalValidatorStatus)
        clearInterval(this.intervalDelegators)
        clearInterval(this.intervalStatus)
    }

    changeInterval(value) {
        clearInterval(this.intervalWidgets)
        this.intervalWidgets = setInterval(this.updateWidgets.bind(this), value)
    }

    addValidatorStatus(key, value, addInactiveIfNotPresent) {
        let status = this.validatorStatus[key] || []
        if (addInactiveIfNotPresent && status.length == 0) {
            status.push("INACTIVE")
        }
        status.push(value)
        this.validatorStatus[key] = status
    }

    updateValidatorStatus() {
        this.validatorStatus = {}
        this.validatorStake = {}
        this.validatorBlocksProduced = {}
        this.validatorBlocksExpected = {}
        this.validatorChunksProduced = {}
        this.validatorChunksExpected = {}
        this.validatorEndorsementsExpected = {}
        this.validatorEndorsementsProduced = {}
        this.validatorShards = {}
        this.epochStartHeight = {}
        this.environments.forEach(async (environment) => {
            const data = await this.rpcFetch(environment, "validators").catch((e) => { console.error(e) })
            if (!data) {
                return
            }
            this.epochStartHeight[environment] = data.result.epoch_start_height
            data.result.current_validators.forEach((validator) => {
                this.addValidatorStatus(validator.account_id, "ACTIVE")
                if (validator.is_slashed) {
                    this.addValidatorStatus(validator.account_id, "SLASHED")
                }
                this.validatorStake[validator.account_id] = validator.stake
                this.validatorShards[validator.account_id] = validator.shards
                this.validatorBlocksProduced[validator.account_id] = validator.num_produced_blocks
                this.validatorBlocksExpected[validator.account_id] = validator.num_expected_blocks
                this.validatorChunksProduced[validator.account_id] = validator.num_produced_chunks
                this.validatorChunksExpected[validator.account_id] = validator.num_expected_chunks
                this.validatorEndorsementsExpected[validator.account_id] = validator.num_expected_endorsements
                this.validatorEndorsementsProduced[validator.account_id] = validator.num_produced_endorsements
                const stake = this.formatStake(validator.stake)
            })
            var seatPrice = 0
            data.result.next_validators.forEach((validator) => {
                if (!this.validatorStatus[validator.account_id] || !this.validatorStatus[validator.account_id].includes("ACTIVE")) {
                    this.addValidatorStatus(validator.account_id, "JOINING", true)
                }
                if (validator.is_slashed) {
                    this.addValidatorStatus(validator.account_id, "SLASHED")
                }
                this.validatorStake[validator.account_id] = validator.stake
                this.validatorShards[validator.account_id] = validator.shards
                const stake = this.formatStake(validator.stake)
                if (seatPrice == 0 || stake < seatPrice) {
                    seatPrice = stake
                }
            })
            this.seatPrice[environment] = seatPrice

            data.result.prev_epoch_kickout.forEach((validator) => {
                this.addValidatorStatus(validator.account_id, "KICKOUT", true)
            })
            data.result.current_proposals.forEach((validator) => {
                if (!this.validatorStatus[validator.account_id] || !this.validatorStatus[validator.account_id].includes("ACTIVE")) {
                    this.addValidatorStatus(validator.account_id, "PROPOSED", true)
                }
                if (validator.is_slashed) {
                    this.addValidatorStatus(validator.account_id, "SLASHED")
                }
                this.validatorStake[validator.account_id] = validator.stake
                this.validatorShards[validator.account_id] = validator.shards
            })
        })
    }

    async initializeNodes() {

        const initializeNodes = this.data.map((d) => d.node)
        const addedNodes = this.nodes.filter((node) => !initializeNodes.includes(node))
        const removedNodes = initializeNodes.filter((node) => !this.nodes.includes(node))

        this.data = this.data.filter((d) => !removedNodes.includes(d.node))
        if (addedNodes.length == 0) {
           this.setState({ data: this.data })
        }

        let promises = []
        addedNodes.forEach((node) => {
            const statusFetch = fetch(this.proxyURL + "http://" + node + "/status")
                .then((response) => response.json())
                .then((response) => {
                    if (response) {
                        this.data.push({ node, environment: response.chain_id, widgets: new Array(8).fill(null), accountId: response.validator_account_id, version: response.version.version })
                        this.setState({ data: this.data })
                    }
                    else {
                        toast.error("Couldn't connect to node " + node + ". Skipping..")
                    }
                })
                .catch((e) => { console.error(e) })
            promises.push(statusFetch)
        })

        Promise.allSettled(promises).then((result) => {
           this.updateConfigInfo()
           this.updateDelegators()
           this.updateValidatorStatus()
        })
    }

    addNode(host) {
        if (!host.includes(":")) {
            host += ":3030"
        }
        toast.info('Adding a new node ' + host + '. This might take a few seconds, please wait..')
        fetch(this.proxyURL + "http://" + host + "/status")
            .then((response) => response.json())
            .then((data) => {
                this.nodes.push(host)
                localStorage.setItem('nodes', JSON.stringify(this.nodes));
                this.initializeNodes()
            })
            .catch((e) => {
                const hostSplit = host.split(":")
                toast.error('No valid host (' + hostSplit[0] + ') or RPC port (' + hostSplit[1] + ') of node not reachable.')
                console.error(e);
            })
    }

    updateWidgets() {
        this.state.data.forEach((row, rowIndex) => {
            fetch(this.proxyURL + "http://" + row.node + "/metrics")
                .then((response) => response.text())
                .then((data) => {
                    if (data.includes("near_block_height_head")) {
                        this.updateState(row, rowIndex, data)
                    }
                })
                .catch((e) => {
                    console.error(e);
                })
        })

    }

    updateNetworkInfo() {
        this.activePeers = {}
        this.environments.forEach((environment) => {
            this.rpcFetch(environment, "network_info")
                .then((data) => {
                    this.activePeers[environment] = data.result.num_active_peers
                })
                .catch((e) => {
                    console.error(e);
                })
        })
    }

    extractMetrics(response) {
        const lines = response.split("\n")
        let metrics = {}
        for (let line of lines) {
            if (line.startsWith("#")) {
                continue
            }
            const data = line.split(" ")
            const name = data[0]
            const value = data[1]
            metrics[name] = value
        }
        return metrics
    }

    updateState(row, rowIndex, response) {

        const metrics = this.extractMetrics(response)

        const accountId = row.accountId
        const blocksProduced = this.validatorBlocksProduced[accountId] || 0
        const blocksExpected = this.validatorBlocksExpected[accountId] || 0
        const chunksProduced = this.validatorChunksProduced[accountId] || 0
        const chunksExpected = this.validatorChunksExpected[accountId] || 0
        const endorsementsProduced = this.validatorEndorsementsProduced[accountId] || 0
        const endorsementsExpected = this.validatorEndorsementsExpected[accountId] || 0

        const currentDate = new Date()
        const timeElapsed = (currentDate - (this.lastTime[rowIndex] || currentDate)) / 1000
        this.lastTime[rowIndex] = currentDate

        const currentBlocksProcessedTotal = metrics['near_block_processed_total']
        const currentTransactionProcessedTotal = metrics['near_transaction_processed_total']
        const deltaBlocks = currentBlocksProcessedTotal - (this.lastBlocksProcessedTotal[rowIndex] || currentBlocksProcessedTotal)
        const deltaTransactions = currentTransactionProcessedTotal - (this.lastTransactionProcessedTotal[rowIndex] || currentTransactionProcessedTotal)
        this.lastBlocksProcessedTotal[rowIndex] = currentBlocksProcessedTotal
        this.lastTransactionProcessedTotal[rowIndex] = currentTransactionProcessedTotal

        const blocksPerMinute = Math.round(deltaBlocks / (timeElapsed / 60))
        const transactionsPerMinute = Math.round(deltaTransactions / timeElapsed)

        const blockHeight = metrics["near_block_height_head"] || 0

        const peers = metrics["near_peer_connections_total"]
        const peersIn = metrics['near_peer_connections{encoding="Proto",peer_type="Inbound",tier="T2"}']
        const peersOut = metrics['near_peer_connections{encoding="Proto",peer_type="Outbound",tier="T2"}']
        const ram = Math.round(metrics["near_memory_usage_bytes"] / 1024 / 1024 / 1024 * 100) / 100
        const cpu = metrics["near_cpu_usage_ratio"]

        const blockProcessingTime = Math.round(metrics["near_block_processing_time_sum"] / metrics["near_block_processing_time_count"] * 1000)

        const now = new Date()
        let hours = now.getHours() + ""
        let min = now.getMinutes() + ""
        if (min.length < 2) {
            min = '0' + min
        }
        if (hours.length < 2) {
            hours = '0' + hours
        }
        const updated = "Last updated at " + hours + ":" + min
        const stake = accountId in this.validatorStake ? this.formatStake(this.validatorStake[accountId]) : null
        const shards = JSON.stringify(this.validatorShards[accountId])
        const maxPeers = 40
        const epochHeight = blockHeight - this.epochStartHeight[row.environment]
        const percentageBlocks = blocksExpected == 0 ? 100 : (blocksProduced * 100 / blocksExpected)
        const percentageChunks = chunksExpected == 0 ? 100 : (chunksProduced * 100 / chunksExpected)
        const percentageEndorsements = endorsementsExpected == 0 ? 100 : (endorsementsProduced * 100 / endorsementsExpected)
        const active = this.validatorStatus[accountId] && this.validatorStatus[accountId].includes("ACTIVE")

        const widgets = [
            {
                title: "Peers",
                updated: updated,
                displayValue: peers,
                subtitle: "Inbound: " + peersIn,
                subtitle2: "Outbound: " + peersOut,
                value: -1 * peers,
                greenThreshold: -maxPeers * 0.75,
                yellowThreshold: -maxPeers * 0.5
            },
            {
                title: "Block height",
                subtitle: row.widgets[1] ? row.widgets[1].subtitle : "",
                subtitle2: blocksPerMinute + " bpm | " + transactionsPerMinute + " tps",
                value: row.widgets[1] ? row.widgets[1].value : "",
                updated: updated,
                displayValue: blockHeight,
                greenThreshold: 10,
                yellowThreshold: 30
            },
            {
                title: "Stake",
                subtitle: stake && this.seatPrice && this.seatPrice[row.environment] ? "seat " + this.seatPrice[row.environment] : "",
                subtitle2: stake && row.accountId in this.delegators ? "delegators " + this.delegators[row.accountId] : "",
                updated: updated,
                displayValue: stake && this.seatPrice[row.environment] ? stake : "",
                value: stake && this.seatPrice && this.seatPrice[row.environment] ? (stake > this.seatPrice[row.environment] ? 0 : 1) : "",
                greenThreshold: 0,
                yellowThreshold: 0
            },
            {
                title: "CPU",
                updated: updated,
                value: cpu / 1600,
                meter: true,
                displayValue: Math.round(cpu * 100 / 1600) + "%",
                greenThreshold: .5,
                yellowThreshold: .75
            },
            {
                title: "RAM",
                updated: updated,
                value: ram / 64,
                meter: true,
                displayValue: ram + " GB",
                greenThreshold: 32 / 64,
                yellowThreshold: 48 / 64
            },
            {
                title: "Endorse",
                displayValue: active ? Math.round(percentageEndorsements * 100) / 100 + "%" : "",
                updated: updated,
                subtitle: active ? endorsementsProduced + "/" + endorsementsExpected : "",
                value: active ? 100 - percentageEndorsements : "",
                value: active ? 100 - percentageEndorsements : "",
                greenThreshold: 0,
                yellowThreshold: 10
            },
            {
                title: "Chunks",
                displayValue: active ? Math.round(percentageChunks * 100) / 100 + "%" : "",
                updated: updated,
                subtitle: active ? chunksProduced + "/" + chunksExpected : "",
                value: active ? 100 - percentageChunks : null,
                greenThreshold: 0,
                yellowThreshold: 10
            },
            {
                title: "Blocks",
                displayValue: active ? Math.round(percentageBlocks * 100) / 100 + "%" : "",
                updated: updated,
                subtitle: active ? blocksProduced + "/" + blocksExpected : "",
                value: active ? 100 - percentageBlocks : "",
                value: active ? 100 - percentageBlocks : "",
                greenThreshold: 0,
                yellowThreshold: 10
            }
        ]

        row.widgets = widgets
        row.validatorStatus = this.validatorStatus[accountId]
        if (this.data[rowIndex] && this.data[rowIndex].accountId == row.accountId) {
            this.data[rowIndex] = row
            this.setState({ data: this.data })
        }

        this.rpcFetch(row.environment, "status")
            .then((json) => {
                const latestBlockHeight = json.result.sync_info.latest_block_height || blockHeight
                const blockDifference = Math.abs(latestBlockHeight - blockHeight) || 0
                row.epochProgress = Math.min(100, Math.round((epochHeight + blockDifference) * 100 / this.epochLength[row.environment] * 100) / 100) + "%"
                const widgets = row.widgets
                const blockHeightWidget = widgets[1]
                blockHeightWidget.subtitle = "left " + blockDifference + ""
                blockHeightWidget.value = blockDifference
                if (this.data[rowIndex] && this.data[rowIndex].accountId == row.accountId) {
                    this.data[rowIndex] = row
                    this.setState({ data: this.data })
                }
            })
            .catch((e) => {
                console.error(e);
            })
    }

    formatStake(stake) {
        return Math.round(stake / 1000000000000000000000000)
    }

    keyPress(e) {
        if (e.keyCode == 13){
            this.addNode(this.state.host)
        }
    }

    removeRow(remove) {
        this.nodes = this.nodes.filter((node) => node !== remove)
        localStorage.setItem('nodes', JSON.stringify(this.nodes));
        this.initializeNodes()
    }

    render() {
        return (
            <div className="content">
                <DynamicContentHead title={"Validator Monitoring"}>
                    <div className="dashboard-form">
                        <label htmlFor="update_interval">Update Interval</label>
                        <select id="update_interval" onChange={(e) => this.changeInterval(e.target.value)}>
                            <option value="15000">15s</option>
                            <option value="30000">30s</option>
                            <option value="60000">1min</option>
                            <option value="300000">5min</option>
                        </select>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="url">RPC Host</label>
                        <input id="url" placeholder="127.0.0.1" type="text" onKeyDown={this.keyPress.bind(this)} onChange={(e) => this.setState({ host: e.target.value })}/>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="submit">&nbsp;</label>
                        <input id="submit" type="button" value="Add Node" onClick={() => this.addNode(this.state.host)}/>
                    </div>
                </DynamicContentHead>
                <div className="dashboard-content half-padding-top">
                    { this.nodes.length > 0 && this.state.data.length === 0 && (
                        <div className="spinner">
                          <div className="lds-ring-black"><div></div><div></div><div></div><div></div></div>
                          <div className="loading-text">Loading...</div>
                        </div>
                    )}
                    { this.state.data && (
                      this.state.data.map((row, rowIndex) => {
                        return <WidgetRow
                            row={row}
                            closable={this.state.data.length > 1}
                            key={rowIndex}
                            removeRow={this.removeRow.bind(this)}
                        />
                      })
                    )}
                </div>
            </div>
        );
    }
}

export default MyComponent;
